@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://use.fontawesome.com/releases/v5.15.3/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&family=Great+Vibes&display=swap');

.font-handwritten {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
}

body {
  background: #f5f5f5;
}
